<template>
  <div class="ticket-page">
    <v-container fluid>
      <transition name="fade">
        <div v-if="isDataLoaded">
          <v-card flat max-width="520" class="mx-auto">
            <v-row class="no-gutters">
              <v-col cols="12" class="text-center">
                <div class="tickets-heading">
                  <div class="sw-page-title">
                    {{ $vuetify.lang.t("$vuetify.yourTicket") }}
                  </div>
                </div>
              </v-col>
              <v-col cols="12" class="sw-mb-7 text-center">
                <div v-html="information"></div>
              </v-col>
              <v-col
                v-if="!tickets || !tickets.length"
                cols="12"
                class="text-center"
              >
                <div v-html="fallbackText"></div>
              </v-col>
              <v-col
                v-for="(ticket, i) in tickets"
                :key="`ticekt-${i}`"
                cols="12"
                class="tickets-codes"
              >
                <v-row class="no-gutters text-center">
                  <v-col cols="12" class="font-weight-regular">
                    {{ ticket.user.first_name }}
                    {{ ticket.user.last_name }}
                  </v-col>
                  <v-col cols="12" class="ticket-qrcode">
                    <img :src="ticket.template.data_url" class="qrcode" />
                  </v-col>
                  <v-col cols="12" class="ticket-code">{{ ticket.code }}</v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </div>
      </transition>
    </v-container>
  </div>
</template>

<script>
import QRCode from "qrcode";

export default {
  data: () => ({
    isDataLoaded: false,
    information: "",
    fallbackText: "",
    tickets: [],
  }),
  computed: {
    user() {
      return this.$store?.getters?.user;
    },
    groupId() {
      return this.$store?.getters?.group?.id;
    },
    appLanguage() {
      return this.$store?.getters?.appLanguage;
    },
    groupPluginId() {
      return this.$route?.params?.group_plugin_id;
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async getPlugin() {
      const params = {
        with_attributes: 1,
        lang: this.appLanguage,
      };

      const response = await this.$http.get(
        `/groups/${this.groupId}/plugins/${this.groupPluginId}`,
        { params },
      );

      this.information =
        response?.data?.data?.attributes?.ticketwizard_ticket_information;
      this.fallbackText =
        response?.data?.data?.attributes?.ticketwizard_ticket_fallback_text;
    },
    async getTickets() {
      if (!this.user.id) return;

      const params = {
        group_plugin_id: this.groupPluginId,
        lang: this.appLanguage,
      };

      const response = await this.$http.get(`/auth/tickets`, { params });

      const opts = {
        errorCorrectionLevel: "high",
        type: "image/png",
        margin: 1,
        scale: 9,
        color: {
          dark: "#000000",
          light: "#ffffff",
        },
      };

      await Promise.all(
        response?.data?.data?.map(async (ticket) => {
          const QRCodeDataURL = await QRCode.toDataURL(ticket.code, opts);

          this.tickets.push({
            code: ticket.code,
            user: {
              first_name: ticket.user.first_name,
              last_name: ticket.user.last_name,
            },
            template: {
              name: ticket.template.name,
              data_url: QRCodeDataURL,
            },
          });
        }),
      );
    },
    async fetchData() {
      try {
        await this.getPlugin();
        await this.getTickets();

        this.isDataLoaded = true;
      } catch (error) {
        this.isDataLoaded = true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.ticket-page {
  padding-top: 243px;

  .ticket-qrcode img.qrcode {
    width: 340px;
    height: auto;
    display: block;
    margin: 0 auto;
    image-rendering: pixelated;
  }

  .ticket-code {
    font-size: 12px;
    padding-top: 4px;
  }
}

@media (max-width: 900px) {
  .ticket-page {
    padding-top: 130px;
    padding-left: 30px;
    padding-right: 30px;

    .tickets-heading span {
      font-size: 45px;
      line-height: 1.1;
    }

    .tickets-codes {
      font-size: 15px;
    }

    .ticket-code {
      font-size: 15px;
    }
  }
}
</style>
